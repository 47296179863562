<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-subject :resources="resources" :exam="exam"></list-subject>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setCreateModalState(true)">
        <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab> -->

    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button>
        <ion-icon
          :ios="chevronBackCircleOutline"
          :md="chevronBackCircleOutline"
        ></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="start">
        <ion-fab-button @click="setCreateModalState(true)">
          <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-button @click="setFilterModalState(true)">
          <ion-icon :ios="funnelOutline" :md="funnelOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>

    <ion-modal
      :is-open="createModalState"
      @didDismiss="setCreateModalState(false)"
    >
      <base-modal
        title="Add Exam Subject"
        @closeModal="setCreateModalState(false)"
      >
        <create-subject
          @closeModal="setCreateModalState(false)"
          :exam_id="exam"
        ></create-subject>
      </base-modal>
    </ion-modal>

    <ion-modal
      :is-open="filterModalState"
      @didDismiss="setFilterModalState(false)"
    >
      <base-modal
        title="Filter Exam Subjects"
        @closeModal="setFilterModalState(false)"
      >
        <filter-subject
          @closeModal="setFilterModalState(false)"
          :exam_id="exam"
        ></filter-subject>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import CreateSubject from "../../Components/Exam/ExamSubject/CreateSubject";
import ListSubject from "../../Components/Exam/ExamSubject/ListSubject";
import FilterSubject from "../../Components/Exam/ExamSubject/FilterSubject.vue"

import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabList,
  IonIcon,
  IonFabButton,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import {
  addOutline,
  chevronBackCircleOutline,
  funnelOutline,
} from "ionicons/icons";

export default {
  props: ["exam", "title"],

  components: {
    BaseLayout,
    BaseModal,
    CreateSubject,
    ListSubject,
    FilterSubject,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonFabList,
    IonIcon,
    IonFabButton,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: true,

      createModalState: false,
      filterModalState: false,

      addOutline,
      chevronBackCircleOutline,
      funnelOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["examSubject/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("examSubject/resources", {
        refresh: refresh,
        resourceId: this.exam,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("examSubject/resources", {
        refresh: true,
        resourceId: this.exam,
      });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },

    setFilterModalState(state) {
      this.filterModalState = state;
    },
  },

  async created() {
    await this.fetch();
  },
};
</script>

<style scoped>
</style>
