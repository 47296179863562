<template>
  <ion-list v-if="resources != null">
    <ion-list-header class="ion-margin-bottom">
      <h3>Exam Subjects</h3>
    </ion-list-header>
    <ion-item
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
      class="hydrated link"
    >
      <ion-label @click="openPopover(item, $event)">
        {{ item.subject.name }}
        <p>Class: {{ item.subject.standard.name }}</p>
        <p>
          {{
            item.exam_schedule
              ? moment(item.exam_schedule.start).format("ll") +
                " " +
                moment(item.exam_schedule.start).format("LT") +
                " to " +
                moment(item.exam_schedule.end).format("LT")
              : "Not Scheduled"
          }}
        </p>
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-align-items-center ion-no-padding">
            <ion-col>
              <p>
                Marks assigned:
                {{
                  item.exam_questions.reduce(
                    (total, question) => total + parseInt(question.marks),
                    0
                  )
                }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center ion-no-padding">
            <ion-col class="ion-no-padding">
              <p>Full: {{ item.full_mark }}</p>
            </ion-col>
            <ion-col>
              <p>Pass: {{ item.pass_mark }}</p>
            </ion-col>
            <ion-col>
              <p>Negative: {{ item.negative_percentage }}%</p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No subjects found</p>
    </ion-list-header>
  </ion-list>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="`${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`"
    >
      <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.question',
          params: {
            exam_subject_id: selectedResource.id,
            title: `${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`,
          },
        }"
        >Questions</ion-item
      >
      <ion-item
        :disabled="
          exam_data.exam_type.name != 'Offline' ||
          selectedResource.exam_subject_state.name != 'Evaluating'
        "
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.student-mark',
          params: {
            exam_subject_id: selectedResource.id,
            title: `${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`,
            exam_name: exam_data.name,
          },
        }"
        >Student Marks
        {{
          selectedResource.exam_subject_state.name != "Evaluating"
            ? `(${selectedResource.exam_subject_state.name})`
            : ""
        }}</ion-item
      >
      <ion-item
        :disabled="
          exam_data.exam_type.name != 'Online' ||
          selectedResource.exam_subject_state.name != 'Evaluating'
        "
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.evaluate',
          params: {
            exam_subject_id: selectedResource.id,
            title: `${selectedResource.subject.standard.name} - ${selectedResource.subject.name}`,
            exam_name: exam_data.name,
          },
        }"
        >Evaluate
        {{
          selectedResource.exam_subject_state.name != "Evaluating"
            ? `(${selectedResource.exam_subject_state.name})`
            : ""
        }}
      </ion-item>
      <ion-item button @click="setResourceToUpdate(selectedResource.id)"
        >Edit</ion-item
      >
      <ion-item
        button
        @click="
          confirmDelete({
            id: selectedResource.id,
            name: selectedResource.name,
          })
        "
        >Delete</ion-item
      >
    </base-popover>
  </ion-popover>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal
      title="Update Exam Subject"
      @closeModal="setUpdateModalState(false)"
    >
      <update-subject
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-subject>
    </base-modal>
  </ion-modal>
</template>
<script>
import {
  IonList,
  IonItem,
  IonListHeader,
  IonLabel,
  alertController,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
  IonModal,
} from "@ionic/vue";

import BasePopover from "../../../Base/BasePopover.vue";
import BaseModal from "../../../Base/BaseModal.vue";
import UpdateSubject from "../ExamSubject/UpdateSubject.vue";
import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    exam: {
      type: String,
      required: true,
    },
  },

  components: {
    IonList,
    IonItem,
    IonListHeader,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonPopover,
    BasePopover,
    BaseModal,
    IonModal,
    UpdateSubject,
  },
  data() {
    return {
      moment,

      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      updateModalState: false,

      resourceToUpdate: {
        id: null,
        name: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    exam_data() {
      return this.$store.getters["exam/specificResource"];
    },
  },

  methods: {
    slide(id) {
      this.$refs[`items-${id}`].$el.open();
    },
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      this.setPopoverState(false);
      const header = `Delete ${resource.name} from exam ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(data) {
      try {
        await this.$store.dispatch("examSubject/deleteResource", data);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
      if (state == false) {
        this.setPopoverState(false);
      }
    },

    async fetchExam() {
      await this.$store.dispatch("exam/specificResource", this.exam);
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },
  },

  created() {
    this.fetchExam();
  },

  unmounted() {
    this.selectedResource = null;
  },
};
</script>
