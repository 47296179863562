<template>
  <form @submit.prevent="createSubject()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.subject_id.$error }">
            <ion-label position="floating">Subject</ion-label>
            <ion-select
              :interface-options="{ cssClass: 'full-screen' }"
              v-if="subjects.length > 0"
              v-model="subject_id"
            >
              <ion-select-option
                v-for="item of subjects"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }} - Class {{ item.standard.name }}
              </ion-select-option>
            </ion-select>
            <ion-select v-else disabled>
              <ion-select-option> No Subjects exists </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.exam_schedule_id.$error }">
            <ion-label position="floating">Exam schedule</ion-label>
            <ion-select
              :interface-options="{ cssClass: 'full-screen' }"
              v-if="exam_schedules.length > 0"
              v-model="exam_schedule_id"
            >
              <ion-select-option
                v-for="item of exam_schedules"
                :key="item.id"
                :value="item.id"
              >
                {{ moment(item.start).format("ll") }}
                {{ moment(item.start).format("LT") }} to
                {{ moment(item.end).format("LT") }}
              </ion-select-option>
            </ion-select>
            <ion-select v-else disabled>
              <ion-select-option> No Schedule exists </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.full_mark.$error }">
            <ion-label position="floating">Full mark</ion-label>
            <ion-input type="number" v-model.number="full_mark"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.pass_mark.$error }">
            <ion-label position="floating">Pass mark</ion-label>
            <ion-input type="number" v-model.number="pass_mark"></ion-input>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.negative_percentage.$error }">
            <ion-label position="floating">Negative Percentage</ion-label>
            <ion-input type="number" v-model.number="negative_percentage"></ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonIcon,
  IonButton,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, minValue, maxValue, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

export default {
  emits: ["closeModal"],
  props: ["exam_id"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonIcon,
    IonButton,
    IonText,
  },
  data() {
    return {
      name: null,
      validationError: null,

      subject_id: null,
      exam_schedule_id: null,
      full_mark: 100,
      pass_mark: 30,
      negative_percentage: 0,

      alertCircleOutline,
      alertCircleSharp,

      moment,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  created() {
    this.fetchAllExamSchedules();
    this.fetchAllSubjects();
  },

  computed: {
    exam_schedules() {
      return this.$store.getters["examSchedule/allResources"];
    },

    subjects() {
      return this.$store.getters["examSubject/allResources"];
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      subject_id: {
        required: helpers.withMessage("is required", required),
      },
      exam_schedule_id: {
        required: helpers.withMessage("is required", required),
      },
      full_mark: {
        required: helpers.withMessage("is required", required),
        minValue: helpers.withMessage("Minimum 10 marks", minValue(10)),
        maxValue: helpers.withMessage("Maximum 200 marks", maxValue(200)),
      },

      pass_mark: {
        required: helpers.withMessage("is required", required),
        minValue: helpers.withMessage("Minimum 1 mark", minValue(1)),
        maxValue: helpers.withMessage(
          "Cannot be more than full mark",
          function minValue(val) {
            return this.full_mark >= val;
          }
        ),
      },

      negative_percentage: {
        required: helpers.withMessage("is required", required),
        minValue: helpers.withMessage("Minimum 0 percent", minValue(0)),
        maxValue: helpers.withMessage("Maximum 400 percent", maxValue(400)),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async createSubject() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        exam_id: this.exam_id,
        subject_id: this.subject_id,
        exam_schedule_id: this.exam_schedule_id,
        full_mark: this.full_mark,
        pass_mark: this.pass_mark,
        negative_percentage: this.negative_percentage,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("examSubject/createResource", data);
        spinner.dismiss();
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
        this.validationError = null;
      }
    },

    async fetchAllExamSchedules() {
      const spinner = await this.loader("Fetching...");
      spinner.present();

      try {
        await this.$store.dispatch("examSchedule/allResources", this.exam_id);
        spinner.dismiss();
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    async fetchAllSubjects() {
      const spinner = await this.loader("Fetching...");
      spinner.present();

      try {
        await this.$store.dispatch("examSubject/allResources", this.exam_id);
        spinner.dismiss();
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },
  },
};
</script>